import React, {useEffect, useState} from 'react';
import Loader from "../components/Loader";
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import axios from "axios";
import moment from "moment";

function BlogSection(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`https://www.tekrevol.com/blogs/wp-json/wp/v2/posts?_embed&categories=761&per_page=5`);
        setUserData(response.data);
        setIsLoaded(false)
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);
    return (
        <>
            {isLoaded ?
                <Loader/>
                :
                <div className="tekrevol_insights p-100">
                    <Container className="h-100">
                        <Row className="h-100">
                            <Col xs={12} md={10} lg={10} xl={8} xxl={8}>
                                <div className="example" data-text="Publications">
                                    <h2>Insights</h2>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_insights_item">
                                <div className="blog_section">
                                    {userData?.map((value, i) => (
                                        <div className="blog_list">
                                            <div className="blog_title">
                                                <Link to={`/blog/${value.slug}`}>
                                                    <h3 dangerouslySetInnerHTML={{__html: value.title.rendered}}/>
                                                </Link>
                                            </div>
                                            <div
                                                className="dates">{moment(value.date).format('MMMM d, YYYY')} </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="btn_load_all">
                                    <Link to="/blogs">
                                        <svg viewBox="0 0 21 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989592 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z"
                                                fill="#F47A1F"/>
                                        </svg>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </>
    );
}

export default BlogSection;