import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";


function LearnersTekrevol({data}) {
    return (
        <div className="learners_section p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5}>
                        <div className="example" data-text={data?.learners_tekrevol_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'learners_title_tekrevol'))}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'learners_description_tekrevol'))}</div>

                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto text-center offset-lg-1">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'learners_image_tekrevol')}`}
                            className="img-fluid"
                            alt={checkData(data, 'learners_title_tekrevol')}/>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default LearnersTekrevol;