import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Longlife({data}) {
    return (
        <div className="tekrevol_dark_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5}>
                        <div className="example" data-text={data?.lifelong_tekrevol_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'lifelong_title_tekrevol'))}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="offset-lg-1">
                        <div>{HtmlParser(checkData(data, 'lifelong_description_tekrevol'))}</div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Longlife;