import React from 'react';
import {Col, Container, Row, Carousel} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function SliderTekrevol({data}) {
    return (
        <div className="tekrevol_dark_bg slider_section p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5}>
                        <div className="example" data-text={data?.slider_tekrevol_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'slider_title_tekrevol'))}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'slider_description_tekrevol'))}</div>
                    </Col>
                    <Col xs={12} md={6} lg={7} xl={7} xxl={7} className="my-auto text-center">
                        <Carousel fade controls={false} indicatorLabels={true}>
                            {data?.slider_image_tekrevol?.map((item, i) => (
                                <Carousel.Item>
                                    <div className="slider_image_item" key={i}>
                                        <img
                                            src={`${mediaBaseURL}${checkData(item, 'image')}`}
                                            className="img-fluid"
                                            alt={checkData(data, 'slider_title_tekrevol')}/>
                                    </div>
                                </Carousel.Item>
                            ))}

                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SliderTekrevol;