import React, {useEffect, useState} from 'react';
import Banner from "./banner";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Seo from "../layout/seo";
import Breadcrumbs from "../components/breadcrumbs";
import Loader from "../components/Loader";
import {checkData} from "../../helper";
import Layout from "../layout/layout";
import Longlife from "./longlife";
import LearnersTekrevol from "./learners_tekrevol";
import SliderTekrevol from "./slider_tekrevol";
import Partner from "../home/partner";
import BlogSection from "./blog_section";

function Index(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`${baseURL}/career-page`);
        setUserData(response.data.data);
        setIsLoaded(false)
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);
    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title_tekrevol')}
                         description={checkData(userData, 'meta_description_tekrevol')}/>
                    <Breadcrumbs link1="TekRevol" link2="Life at TekRevol"/>
                    <Banner data={userData}/>
                    <Longlife data={userData}/>
                    <LearnersTekrevol data={userData}/>
                    <BlogSection/>
                    <SliderTekrevol data={userData}/>
                    <Partner/>
                </>
            }
        </Layout>
    );
}

export default Index;